




































































































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";
import OrgSearchAndAdd from "@/components/ui/OrgSearchAndAdd.vue";
import OrgCategory from "@/components/ui/OrgCategory.vue";
import AddressDialog, {
  AddressData,
} from "@/components/dialog/AddressDialog.vue";
import { Org } from "@/models/Org";

import OrgApi from "@/apis/OrgApi";
import ClientApi from "@/apis/care/ClientApi";
import { BaseRepo } from "@/models/BaseRepo";
import { OrgOrgItems } from "@/views/models/admin/OrgOrgItems";
import { ValidationObserver } from "vee-validate";
import OrgSearch from "@/components/ui/OrgSearch.vue";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
    OrgCategory,
    OrgSearchAndAdd,
    AddressDialog,
    OrgSearch,
  },
})
export default class EditForm extends BaseView {
  $refs!: {
    addressDialog: InstanceType<typeof AddressDialog>;
    observer: InstanceType<typeof ValidationObserver>;
    orgSearch: InstanceType<typeof OrgSearch>;
  };

  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit() {
    return this.returnItem;
  }

  @Prop() StockType!: boolean;

  private isAddressDialog = false;
  private org!: Org;
  private orgApi: OrgApi = new OrgApi();
  private clientApi: ClientApi = new ClientApi();
  private excludeValue = [1];
  private selectedCategoryId = 2;
  private orgCategorys: Org[] = [];

  private returnItem = {
    id: 0,
    partner_id: 0,
    client_id: 0,
    bookMark: "0",
  } as OrgOrgItems;

  private params: Org = {
    id: 0,
    name: "",
    org_category_id: 2,
    tel: "",
    sido: "",
    sigungu: "",
    address: "",
    address_detail: "",
    biz_num: "",
    remark: "",
  };

  protected async created() {
    try {
      this.isConnecting = true;
      const repo: BaseRepo = await this.orgApi.categories(this.excludeValue);
      this.orgCategorys = repo.data;
      this.params.org_category_id = this.orgCategorys[0].id;
    } catch (e) {
      this.handleError(e);
    } finally {
      this.isConnecting = false;
    }
  }

  private async save() {
    if (this.isConnecting) return;

    if (this.params.id > -1 && this.params.name === "") {
      this.$alert("알림", "시설명을 입력해주세요.");
      return;
    }
    const result = await this.$refs.observer.validate();
    if (!result) return;

    this.isConnecting = true;
    try {
      const repo = await this.clientApi.createOrg(this.params);
      // this.returnItem = repo.data;
      if (repo.data.orgOrg == null) {
        this.returnItem.id = -1;
        this.returnItem.client_id = repo.data.org.id;
        this.returnItem.partner_id = -1;
        this.returnItem.bookMark = "1";
      } else {
        this.returnItem.id = repo.data.orgOrg.id;
        this.returnItem.client_id = repo.data.orgOrg.client_id;
        this.returnItem.partner_id = repo.data.orgOrg.partner_id;
        this.returnItem.bookMark = "2";
      }
      this.returnItem.org = repo.data.org;
      this.submit();
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
  }

  private onSelectOrg(org: Org) {
    this.params = { ...org };
  }

  private onClickAddressField() {
    this.isAddressDialog = true;
    this.$nextTick(() => {
      this.$refs.addressDialog.addListenr((address: AddressData) => {
        this.params.sido = address.sido;
        this.params.sigungu = address.sigungu;
        this.params.address = address.address;
        this.params.address_detail = address.address_detail;
        this.isAddressDialog = false;
      });
    });
  }

  private initParams(id = 0, org_category_id = 0) {
    this.params = {
      id,
      name: "",
      org_category_id,
      tel: "",
      sido: "",
      sigungu: "",
      address: "",
      address_detail: "",
      biz_num: "",
      remark: "",
    };
  }

  private onClickAddNewOrg() {
    this.initParams(-1, this.params.org_category_id);
    this.$refs.orgSearch.clearSelectedOrg();
  }

  private onChangeOrgCategory(no: number) {
    this.initParams(0, no);
    this.selectedCategoryId = no;
    this.params.org_category_id = no;

    this.$refs.orgSearch.clearSelectedOrg();
  }
}
